import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain, PostFeed, PostFeedRaise } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { graphql } from 'gatsby';

const _ = require('lodash');

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import TopicCard from '../components/TopicCard';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }

  .tag-list {
    padding-top: 5vw !important;
  }
`;

const Topics: React.FC = props => {
  const tags = _.uniq(
    _.flatten(
      props.data.allMarkdownRemark.edges.map((edge: any) => {
        return _.castArray(_.get(edge, 'node.frontmatter.tags', []));
      }),
    ),
  );

  var index = tags.indexOf('Projekte');
  if (index > -1) {
    tags.splice(index, 1);
  }
  index = tags.indexOf('SnAIp');
  if (index > -1) {
    tags.splice(index, 1);
  }

  return (
    <IndexLayout>
      <Helmet>
        <title>Themen</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader>
              <PostFullTitle>Themen</PostFullTitle>
            </PostFullHeader>
            <div className="tag-list">
              <div css={inner}>
                <div css={[PostFeed, PostFeedRaise]}>
                  {tags.map((tag: string) => (
                    <TopicCard key={tag} tag={tag} />
                  ))}
                </div>
              </div>
            </div>
          </article>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 200, sort: { fields: [frontmatter___tags], order: ASC }, filter: {frontmatter: {draft: {eq: false}}}) {
      edges {
        node {
          timeToRead
          frontmatter {
            draft
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Topics;
